
import { defineComponent, toRefs, watch, PropType } from "vue";
import { useOfficialDoc } from "@/network/officialDoc.ts";

export default defineComponent({
  props: {
    setting: {
      type: Object as PropType<{
        id: number;
        visible: boolean;
        approve: boolean;
      }>,
      required: true,
      default: () => ({ id: 0, visible: false, approve: false }),
    },
  },
  setup(props) {
    const { id, visible, approve } = toRefs(props.setting as any);
    const [isLoading, item, find] = useOfficialDoc();

    watch(visible, (v: number) => {
      if (v) {
        find(id.value);
      }
    });
    return {
      approve,
      visibleShow: visible,
      isLoading,
      item,
    };
  },
});
